
import { Component, Prop, Vue } from "vue-property-decorator";
/**
 * SLOT is required!
 */

@Component
export default class FormInput extends Vue {
  @Prop() value!: string;
  @Prop({ default: false }) disabled!: boolean;
  get labelid(): string {
    return this.$slots.default
      ? this.$slots.default![0].text!.replace(" ", "-").toLowerCase()
      : this.value;
  }
}
